import {
  TCreateAuctionFormValues,
  TUpdateAuctionFormValues,
} from "@components";

export function encodeOptionValues(
  values: TCreateAuctionFormValues | TUpdateAuctionFormValues
) {
  const agency = values.isOnline ? "10" : values.agency;
  function hasValue(object?: object) {
    if (!object) return false;
    return Object.keys(object).length > 0;
  }

  let deposits =
    values.deposits?.length &&
    values.deposits.filter(Boolean).reduce((object, { assets, deposit }) => {
      if (deposit === undefined || !assets.length) return object;
      return Object.assign(
        object,
        assets.reduce((res, asset) => {
          return Object.assign(res, { [asset as string]: deposit });
        }, {})
      );
    }, {});
  if (values.isOnline && !values.deposits.length) {
    deposits = {};
  }

  const auctioneer_fees =
    values.auctioneerFees?.length &&
    values.auctioneerFees.filter(Boolean).reduce((object, { assets, fee }) => {
      if (fee === undefined || !assets.length) return object;
      return Object.assign(
        object,
        assets.reduce((res, asset) => {
          return Object.assign(res, { [asset as string]: fee });
        }, {})
      );
    }, {});

  return {
    is_online: values.isOnline,
    auction_date: values.date,
    auction_end_date: values.auctionEndDate,
    timezone: values.timezone,
    payment_account: values.paymentAccount,
    payment_conditions: values.paymentConditions,
    ...(!values.isOnline && { auctioneer_fees: auctioneer_fees }),
    deposits,
    agency,
    sealed: values.sealedBid,
    conversion_rate: values.conversionRate,
    unhcr_lot_count: values.nonWfpLots,
    unhcr_services: values.unhcrServicesFee,
    auctioneer_invoice: values.auctioneerInvoice,
    co_focal_point: hasValue(values.focalPoint)
      ? {
          full_name: values.focalPoint!.fullName,
          email: values.focalPoint!.email,
          phone: values.focalPoint!.phone,
        }
      : undefined,
    gas_team_representative: hasValue(values.representative)
      ? {
          full_name: values.representative!.fullName,
          email: values.representative!.email,
          phone: values.representative!.phone,
        }
      : undefined,
    unhcr_supervisor: hasValue(values.supervisor)
      ? {
          full_name: values.supervisor!.fullName,
          email: values.supervisor!.email,
          phone: values.supervisor!.phone,
        }
      : undefined,
    location_geoname_id: values.city,
    currency_code: values.currency,
    send_email_bid_was_outbid: values.sendEmailBidWasOutbid,
    send_email_bidder_won: values.sendEmailBidderWon,
    email_language: values.emailLanguage,
    requires_online_deposits: values.requiresOnlineDeposits,
    enable_bank_transfer: values.enableBankTransfer,
    enable_online_payment: values.enableOnlinePayment,
  };
}
