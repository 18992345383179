import { FC, useCallback, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import cityTimezones, { CityData } from "city-timezones";
import { Form as BaseForm, Formik, FormikHelpers } from "formik";
import styled from "styled-components";

import { API } from "@api";
import {
  FileField,
  Icon,
  Modal,
  NumberField,
  SwitchField,
  TextField,
} from "@components";
import { ButtonGroupField } from "@components/ButtonGroupField";
import { DateField } from "@components/DateField";
import { ErrorRenderer } from "@components/FormError";
import { useI18n } from "@i18n";
import { device } from "@styles/breakpoints";
import {
  Agency,
  FileType,
  getCityName,
  ICity,
  ICountry,
  IEmailLanguage,
} from "@utils";
import { Button } from "../../Button";
import { Label } from "../../Label";
import { SelectField } from "../../SelectField";
import { TextAreaField } from "../../TextAreaField";
import { AuctioneerFeeByAssetField } from "./AuctioneerFeeByAssetField";
import {
  MAX_CONVERSION_RATE_VALUE,
  MAX_DEPOSIT_VALUE,
  MAX_FEE_VALUE,
  MIN_CONVERSION_RATE_VALUE,
  MIN_DEPOSIT_VALUE,
  MIN_FEE_VALUE,
  MIN_NON_WFP_LOTS_VALUE,
  MIN_UNHCR_SERVICES_VALUE,
} from "./consts";
import { DepositByAssetField } from "./DepositByAssetField";
import { INITIAL_VALUES } from "./initialValues";
import { validateForm, VALIDATION_SCHEMA } from "./validationSchema";

export type TCreateAuctionFormValues = typeof INITIAL_VALUES;

type Props = {
  loading?: boolean;
  onSubmit: (
    values: TCreateAuctionFormValues,
    helpers: FormikHelpers<TCreateAuctionFormValues>
  ) => Promise<void>;
};

const Form = styled(BaseForm)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;

  span {
    min-width: 280px;
  }
  div {
    margin-top: 0;
  }
`;

const ErrorWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.tripleMargin};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.tripleMargin};

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.tripleMargin};
  }

  @media ${device.tablet} {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  max-width: 300px;
`;

const ImageDeleteButton = styled.div`
  position: absolute;
  right: -5px;
  top: -10px;
  cursor: pointer;
`;

const RowWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelBox = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
  width: 200px;

  & ${Label} {
    margin-right: auto;
  }
`;

const TooltipContent = styled.div`
  max-width: 20rem;
`;

export const CreateAuctionForm: FC<Props> = ({ loading, onSubmit }) => {
  const { t } = useI18n();
  const [cities, setCities] = useState<ICity[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [currencies, setCurrencies] = useState<{ code: string }[]>([]);
  const [emailLanguages, setEmailLanguages] = useState<IEmailLanguage[]>([]);
  const [conversionRateDisabled, setConversionRateDisabled] = useState(false);
  const [auctionImage, setAuctionImage] = useState<string>("");
  const [uploadedPhoto, setUploadedPhoto] = useState<any>("");
  const [timezones, setTimezones] = useState<Array<any>>([]);

  useEffect(() => {
    async function getCountries() {
      try {
        const countries = await API.users.countries();
        setCountries(countries);
      } catch (_) {
        setCountries([]);
      }
    }

    async function getCurrencies() {
      try {
        const currencies = await API.utils.currencies();
        setCurrencies(currencies);
      } catch (_) {
        setCurrencies([]);
      }
    }

    async function getEmailLanguages() {
      try {
        const languages = await API.auctions.getEmailLanguages();
        setEmailLanguages(languages);
      } catch (_) {
        setEmailLanguages([]);
      }
    }

    async function getTimezones() {
      try {
        const timezones = await API.utils.getTimezones();
        setTimezones(timezones);
      } catch (_) {
        setTimezones([]);
      }
    }

    getCountries();
    getCurrencies();
    getEmailLanguages();
    getTimezones();
  }, []);

  useEffect(() => {
    createUrl(uploadedPhoto);
  }, [uploadedPhoto]);

  const createUrl = (files: any): void => {
    let url: any = [];
    for (let file in files) {
      typeof files[file] === "object" &&
        url.push(URL.createObjectURL(files[file]));
    }
    setAuctionImage(url[0]);
  };

  const handlePhotoField = (photo: any, setFieldValue: any) => {
    setFieldValue("auctionImage", photo[0]);
    setUploadedPhoto(photo);
  };

  async function getCitiesByCountry(countryId: number) {
    try {
      const cities = await API.users.citiesByCountry(countryId);
      setCities(cities);
    } catch (_) {
      setCities([]);
    }
  }

  const handleSubmit = useCallback(
    async (values, helpers) => {
      helpers.setSubmitting(true);
      await onSubmit(values, helpers);
      helpers.setSubmitting(false);
    },
    [onSubmit]
  );

  const handleCountryChange = async (countryId: number) => {
    getCitiesByCountry(countryId);
  };

  return (
    <Formik<TCreateAuctionFormValues>
      initialValues={INITIAL_VALUES}
      validate={validateForm}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        isValid,
        touched,
        values,
        handleSubmit,
        handleChange: onChange,
        setValues,
        setFieldValue,
        resetForm,
      }) => {
        const hasMaxFeeValue: boolean = values.auctioneerFees.some(
          ({ fee }) => fee && fee > MAX_FEE_VALUE
        );
        const hasMinFeeValue: boolean = values.auctioneerFees.some(
          ({ fee }) => fee && fee < MIN_FEE_VALUE
        );
        const hasMaxDepositValue: boolean = values.deposits.some(
          ({ deposit }) => deposit && deposit > MAX_DEPOSIT_VALUE
        );
        const hasMinDepositValue: boolean = values.deposits.some(
          ({ deposit }) => deposit && deposit < MIN_DEPOSIT_VALUE
        );

        return (
          <Form onSubmit={handleSubmit}>
            <Modal
              width="70%"
              title={t("pages.createAuction.title")}
              primaryActions={<></>}
              actions={
                <>
                  <Button
                    bordered
                    onClick={() => {
                      setValues(INITIAL_VALUES);
                      setAuctionImage("");
                      setUploadedPhoto("");
                    }}
                  >
                    {t<string>("auctionForm.clear")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!Object.values(touched).length || !isValid}
                    onClick={() => null}
                    loading={loading}
                  >
                    {t<string>("auctionForm.submit")}
                  </Button>
                </>
              }
              onClose={() => null}
              fixed
            >
              <Row>
                <SwitchContainer>
                  <span>{t("auctionForm.holdOnline")}</span>
                  <SwitchField name="isOnline" resetForm={resetForm} />
                </SwitchContainer>
              </Row>
              <Label>{t("auctionForm.location")}</Label>
              <Row>
                <SelectField
                  name="country"
                  placeholder={t("auctionForm.country")}
                  options={countries.map((country) => ({
                    label: country.name,
                    value: country.geoname_id,
                  }))}
                  onChange={(event) => {
                    setFieldValue("city", "");
                    onChange(event);
                    handleCountryChange(Number(event.target.value));
                  }}
                />
                <SelectField
                  name="city"
                  placeholder={t("auctionForm.city")}
                  options={cities.map((city) => ({
                    label: getCityName(city),
                    value: city.geoname_id,
                  }))}
                  disabled={!Boolean(values.country) || cities.length === 0}
                  onChange={(event) => {
                    onChange(event);
                    const city = cities.find(
                      (city) => city.geoname_id === +event.target.value
                    )?.name_ascii;
                    if (!city) return;
                    let cityLookup: CityData | undefined;
                    cityLookup = cityTimezones.lookupViaCity(city)[0];
                    if (!cityLookup) {
                      const country = countries.find(
                        (i): boolean => i.geoname_id === +values.country
                      )?.code2;
                      cityLookup = cityTimezones.cityMapping.find(
                        (cityData): boolean => cityData.iso2 === country
                      );
                    }
                    if (!cityLookup) return;
                    setFieldValue("timezone", cityLookup.timezone);
                  }}
                />
              </Row>

              <Row>
                <DateField
                  name="date"
                  placeholder={t("auctionForm.date.placeholder")}
                  label={t("auctionForm.date.label")}
                />
                {!values.isOnline ? (
                  <SelectField
                    name="currency"
                    placeholder={t("auctionForm.currency.placeholder")}
                    label={t("auctionForm.currency.label")}
                    options={currencies.map((currency) => ({
                      label: currency.code,
                      value: currency.code,
                    }))}
                    onChange={(event) => {
                      onChange(event);
                      const currency = event.target.value;
                      if (currency === "USD") {
                        setConversionRateDisabled(true);
                        setFieldValue("conversionRate", 1);
                      } else {
                        setConversionRateDisabled(false);
                        setFieldValue("conversionRate", "");
                      }
                    }}
                  />
                ) : (
                  <>
                    <DateField
                      name="auctionEndDate"
                      placeholder={t("auctionForm.auctionEndDate.placeholder")}
                      label={t("auctionForm.auctionEndDate.label")}
                    />
                    <SelectField
                      name="timezone"
                      placeholder="Select timezone"
                      label="Select timezone"
                      options={timezones.map((zone) => ({
                        label: zone.display_name,
                        value: zone.value,
                      }))}
                    />
                  </>
                )}
              </Row>

              {values.isOnline && (
                <>
                  <Row>
                    <SelectField
                      name="currency"
                      placeholder={t("auctionForm.currency.placeholder")}
                      label={t("auctionForm.currency.label")}
                      options={currencies.map((currency) => ({
                        label: currency.code,
                        value: currency.code,
                      }))}
                      onChange={(event) => {
                        onChange(event);
                        const currency = event.target.value;
                        if (currency === "USD") {
                          setConversionRateDisabled(true);
                          setFieldValue("conversionRate", 1);
                        } else {
                          setConversionRateDisabled(false);
                          setFieldValue("conversionRate", "");
                        }
                      }}
                    />
                  </Row>
                </>
              )}
              <Row>
                <NumberField
                  name="conversionRate"
                  label={t("auctionForm.conversionRate.label")}
                  placeholder={t("auctionForm.conversionRate.placeholder")}
                  min={MIN_CONVERSION_RATE_VALUE}
                  max={MAX_CONVERSION_RATE_VALUE}
                  step="0.001"
                  disabled={conversionRateDisabled}
                />
              </Row>
              {values.isOnline && (
                <>
                  <Row>
                    <SwitchContainer>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <Label className="mr-6">
                          {t("auctionForm.depositToggle")}
                        </Label>
                      </div>
                      <div style={{ alignItems: "end" }}>
                        <SwitchField
                          name="requiresOnlineDeposits"
                          disabled={!!values.deposits.length}
                        />
                      </div>
                    </SwitchContainer>
                  </Row>
                  {values.requiresOnlineDeposits && (
                    <Row>
                      <div>
                        <DepositByAssetField />
                        {hasMinDepositValue && (
                          <ErrorWrapper>
                            <ErrorRenderer children="auctionForm.errors.deposit.min" />
                          </ErrorWrapper>
                        )}
                        {hasMaxDepositValue && (
                          <ErrorWrapper>
                            <ErrorRenderer children="auctionForm.errors.deposit.max" />
                          </ErrorWrapper>
                        )}
                      </div>
                    </Row>
                  )}
                  <Row>
                    <SwitchContainer>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <Label className="mr-6">
                          {t("auctionForm.emailNotification.outbid")}
                        </Label>
                        <div id="outbid-tooltip">
                          <Icon.InfoCircle />
                        </div>
                      </div>
                      <div style={{ alignItems: "end" }}>
                        <SwitchField name="sendEmailBidWasOutbid" />
                      </div>
                    </SwitchContainer>
                    <Tooltip anchorId="outbid-tooltip">
                      <TooltipContent>
                        {t("auctionForm.emailNotificationTooltip.outbid")}
                      </TooltipContent>
                    </Tooltip>
                  </Row>
                  <Row>
                    <SwitchContainer>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <Label className="mr-6">
                          {t("auctionForm.emailNotification.winner")}
                        </Label>
                        <div id="winner-tooltip">
                          <Icon.InfoCircle />
                        </div>
                      </div>
                      <div style={{ alignItems: "end" }}>
                        <SwitchField name="sendEmailBidderWon" />
                      </div>
                    </SwitchContainer>
                    <Tooltip anchorId="winner-tooltip">
                      <TooltipContent>
                        {t("auctionForm.emailNotificationTooltip.winner")}
                      </TooltipContent>
                    </Tooltip>
                  </Row>
                  <Row>
                    <SelectField
                      name="emailLanguage"
                      placeholder={t("auctionForm.emailLanguage")}
                      options={emailLanguages.map((language) => ({
                        label: language.name,
                        value: language.code,
                      }))}
                    />
                  </Row>
                  <Row>
                    <SwitchContainer>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <Label className="mr-6">
                          {t("auctionForm.enableBankTransfer")}
                        </Label>
                      </div>
                      <div style={{ alignItems: "end" }}>
                        <SwitchField name="enableBankTransfer" />
                      </div>
                    </SwitchContainer>
                  </Row>
                  <Row>
                    <SwitchContainer>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <Label className="mr-6">
                          {t("auctionForm.enableOnlinePayment")}
                        </Label>
                      </div>
                      <div style={{ alignItems: "end" }}>
                        <SwitchField name="enableOnlinePayment" />
                      </div>
                    </SwitchContainer>
                  </Row>
                </>
              )}
              <Row>
                <RowWithLabel>
                  <LabelBox>
                    <Label className="mr-6">
                      {t("auctionForm.paymentAccount")}
                    </Label>
                    <div id="payment-account-tooltip">
                      <Icon.InfoCircle />
                    </div>
                  </LabelBox>
                  <TextAreaField
                    name="paymentAccount"
                    placeholder={t("auctionForm.paymentAccount")}
                  />
                </RowWithLabel>
                <Tooltip anchorId="payment-account-tooltip">
                  <TooltipContent>
                    {t("auctionForm.paymentAccountTooltip")}
                  </TooltipContent>
                </Tooltip>
              </Row>
              <Row>
                <RowWithLabel>
                  <LabelBox>
                    <Label className="mr-6">
                      {t("auctionForm.paymentConditions")}
                    </Label>
                    <div id="payment-conditions-tooltip">
                      <Icon.InfoCircle />
                    </div>
                  </LabelBox>
                  <TextAreaField
                    name="paymentConditions"
                    placeholder={t("auctionForm.paymentConditions")}
                  />
                </RowWithLabel>
                <Tooltip anchorId="payment-conditions-tooltip">
                  <TooltipContent>
                    {t("auctionForm.paymentConditionsTooltip")}
                  </TooltipContent>
                </Tooltip>
              </Row>
              {!values.isOnline && (
                <>
                  <div>
                    <AuctioneerFeeByAssetField />
                    {hasMinFeeValue && (
                      <ErrorWrapper>
                        <ErrorRenderer children="auctionForm.errors.fee.min" />
                      </ErrorWrapper>
                    )}
                    {hasMaxFeeValue && (
                      <ErrorWrapper>
                        <ErrorRenderer children="auctionForm.errors.fee.max" />
                      </ErrorWrapper>
                    )}
                  </div>

                  <div>
                    <DepositByAssetField />
                    {hasMinDepositValue && (
                      <ErrorWrapper>
                        <ErrorRenderer children="auctionForm.errors.deposit.min" />
                      </ErrorWrapper>
                    )}
                    {hasMaxDepositValue && (
                      <ErrorWrapper>
                        <ErrorRenderer children="auctionForm.errors.deposit.max" />
                      </ErrorWrapper>
                    )}
                  </div>

                  <Row>
                    <ButtonGroupField
                      name="agency"
                      label={t("auctionForm.agency")}
                      options={[
                        { label: "UNHCR", value: Agency.UNHCR },
                        { label: "WFP", value: Agency.WFP },
                      ]}
                      onChange={(agency: Agency) => {
                        if (agency === Agency.WFP) {
                          setFieldValue(
                            "supervisor",
                            INITIAL_VALUES.supervisor
                          );
                          setFieldValue(
                            "nonWfpLots",
                            INITIAL_VALUES.nonWfpLots
                          );
                          setFieldValue(
                            "unhcrServicesFee",
                            INITIAL_VALUES.unhcrServicesFee
                          );
                        }
                      }}
                    />
                    <SwitchField
                      name="sealedBid"
                      label={
                        values.sealedBid
                          ? t("auctionForm.sealedBid")
                          : t("auctionForm.public")
                      }
                    />
                  </Row>
                </>
              )}

              {values.agency === Agency.UNHCR && !values.isOnline && (
                <>
                  <Row>
                    <NumberField
                      name="nonWfpLots"
                      label={t("auctionForm.nonWfpLots.label")}
                      placeholder={t("auctionForm.nonWfpLots.placeholder")}
                      step={1}
                      min={MIN_NON_WFP_LOTS_VALUE}
                    />
                    <NumberField
                      name="unhcrServicesFee"
                      label={t("auctionForm.unhcrServicesFee.label")}
                      placeholder={t(
                        "auctionForm.unhcrServicesFee.placeholder"
                      )}
                      step={0.01}
                      min={MIN_UNHCR_SERVICES_VALUE}
                    />
                  </Row>
                  <Label>{t("auctionForm.supervisor")}</Label>
                  <Row>
                    <TextField
                      name="supervisor.fullName"
                      placeholder={t("auctionForm.fullName")}
                    />
                    <TextField
                      name="supervisor.email"
                      placeholder={t("auctionForm.email")}
                    />
                  </Row>
                  <Row>
                    <TextField
                      name="supervisor.phone"
                      placeholder={t("auctionForm.phone")}
                    />
                  </Row>
                </>
              )}

              {values.agency === Agency.WFP && !values.isOnline && (
                <Row>
                  <NumberField
                    name="auctioneerInvoice"
                    label={t("auctionForm.auctioneerInvoice.label")}
                    placeholder={t("auctionForm.auctioneerInvoice.label")}
                    step={0.01}
                    min={MIN_UNHCR_SERVICES_VALUE}
                  />
                </Row>
              )}

              <Label>{t("auctionForm.focalPoint")}</Label>
              <Row>
                <TextField
                  name="focalPoint.fullName"
                  placeholder={t("auctionForm.fullName")}
                />
                <TextField
                  name="focalPoint.email"
                  placeholder={t("auctionForm.email")}
                />
              </Row>
              <Row>
                <TextField
                  name="focalPoint.phone"
                  placeholder={t("auctionForm.phone")}
                />
              </Row>

              <Label>{t("auctionForm.representative")}</Label>
              <Row>
                <TextField
                  name="representative.fullName"
                  placeholder={t("auctionForm.fullName")}
                />
                <TextField
                  name="representative.email"
                  placeholder={t("auctionForm.email")}
                />
              </Row>
              <Row>
                <TextField
                  name="representative.phone"
                  placeholder={t("auctionForm.phone")}
                />
              </Row>
              {values.isOnline && (
                <Row>
                  <FileField
                    name="auctionImage"
                    accept="image/*"
                    label={t("lotForm.photo.label")}
                    placeholder={t("lotForm.photo.placeholder")}
                    onChange={(event) => {
                      handlePhotoField(
                        event.currentTarget.files,
                        setFieldValue
                      );
                    }}
                  />
                </Row>
              )}
              {values.isOnline && auctionImage && (
                <ImageContainer>
                  <ImageDeleteButton
                    onClick={() => {
                      setFieldValue("auctionImage", null);
                      setAuctionImage("");
                      setUploadedPhoto("");
                    }}
                  >
                    x
                  </ImageDeleteButton>
                  <img
                    src={auctionImage}
                    alt="auction logo"
                    style={{ maxWidth: "300px" }}
                  />
                </ImageContainer>
              )}
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
